import { useDateFormat } from "@/composables/useDateFormat";

export function isNewDayMessageGeneric<T>(index: number, messages: T[], getDate: (msg: T) => string) {
	return index === 0 || (index !== 0 && useDateFormat(getDate(messages[index])) !== useDateFormat(getDate(messages[index - 1])));
}

export function isNewDayMessage(index: number, messages: IMessage[]) {
	return isNewDayMessageGeneric(index, messages, (msg) => msg.created);
}

export function isNewDayManagerMessage(index: number, messages: IOrgMessage[]) {
	return isNewDayMessageGeneric(index, messages, (msg) => msg.created);
}
